<template>
	<div>
		<!-- <a href="https://wa.me/526121535535?text=I want information about your yatchs services " class="whatsapp" target="_blank"> 
			<span class="icon-whatsapp"></span>
		</a> -->

		<a href="http://instagram.com/_u/gobajaasasailing/" class="instagram" target="_blank"> 
			<i class="icon-instagram whatsapp-icon"></i>
		</a>

		<a :href="isCelular() ? 'fb://profile/100063654668408' : 'https://www.facebook.com/GoBajaSailing'" class="facebook" target="_blank"> 
			<i class="icon-facebook whatsapp-icon"></i>
		</a>
	</div>
</template>

<script>
export default {

  name: 'BottomButtons',

  data () {
    return {
    	isCelular() {
	    if (screen.width <= 767) {
	      return true
	    } else {
	      return false
	    }
	  },
    }
  }
}
</script>

<style lang="css" scoped>
.whatsapp {
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:35px !important;
  z-index:100;
}

.instagram {
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:110px;
  background-color:#833AB4;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:35px;
  z-index:100;
}

.facebook {
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:180px;
  background-color:#3b5998;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:38px;
  z-index:100;
}
</style>