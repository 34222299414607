<template>
	<div class="pb-5">
			<div class="container header-fix"  v-if="!sent">
			<vue-element-loading :active="sending" spinner="spinner" color="#1089ff" :is-full-screen="true"/>
			
			<div class="row justify-content-center mb-5">
        <div class="col-md-7 text-center">
         	<span class="text-serif text-primary">Quote a trip</span>
         	<h3 class="heading-92913 text-black text-center">Quoter</h3>
        </div>
    	</div>

    	<errorlist :errors="errors" />

			<div class="row">
				<vue-element-loading :active="loading" spinner="spinner" color="#1089ff"/>


				<div class="col-md-3">
					<label>Yatch</label>
					<select class="form-control form-control-lg" :class="errorLine('yatch')" v-model="yatch" :disabled="disableYatch" @change="selectYatchAndGetPrices(yatch.id)">
					 	<option v-for="(yatch, index) in yatchs" :value="yatch">
					 		{{yatch.name}}
					 	</option>
					</select>		
				</div>

				<div class="col-md-3">
					<label>How Many people?</label>
					<select class="form-control form-control-lg"  :class="errorLine('people')" v-model="people" @change="selectPeople" :disabled="disabledPeople">
						<option value="" selected disabled hidden>Choose here</option>
					 	<option v-for="n in maxPeople">{{n}}</option>
					 	
					</select>		
				</div>
				<div class="col-md-3">
					<label>Date</label><br>
					<div style="width: 100%;">
					<date-picker  v-model="date" valueType="format" input-class="fix w-100"  :class="errorLine('date')" placeholder="Select date" @change="selectDate" :disabled="disabledDate" :disabled-date="disabledBeforeToday"></date-picker>
					</div>
				</div>

				<div class="col-md-3">
					<label>Hours</label>
					<select name="" id="" v-model="hours" class="form-control form-control-lg"  :class="errorLine('hours')" 
					:disabled="disabledHours" @change="calculatePrice">
						<option value="" selected disabled hidden>Choose here</option>
						<option v-for="h in prices" :value="h">
							{{h.hours}}
						</option>
						
					</select>
				</div>

				<div class="col">
					<div class="text-center pt-5" :class="[isCelular() ? 'row justify-content-center mt-5' : '']">					    	
		    		<h1 v-show="total!=''">{{total}}</h1>
		    	</div>
				</div>

			</div>


			<br>
			<div class="row">
       	<div class="col-md-4 form-group">
         	<label for="fname">Name</label>
          <input type="text" id="fname" v-model="contacto.name"  :class="errorLine('name')" class="form-control form-control-lg">
        </div>

        <div class="col-md-4 form-group">
          <label for="fname">Email Adress</label>
          <input type="text" id="fname" v-model="contacto.email"  :class="errorLine('email')" class="form-control form-control-lg">
        </div>

        <div class="col-md-4 form-group">
          <label for="fname">Mobile</label>
          <VuePhoneNumberInput size="lg" :no-flags="false" default-country-code="MX" :only-countries="['MX','US','FR','IT','DE','GB','ES','RU']" :error="!!errors['mobile']"
          type="text" id="fname" v-model="contacto.mobile"  @update="phone" :class="errorLine('mobile') "/>
        </div>
    	</div>
	        
	    <div class="row">
	      <div class="col-md-12 form-group">
	        <label for="message">Message</label>
	        <textarea name="" id="message" v-model="contacto.message"  :class="errorLine('message')" cols="30" rows="10" class="form-control"></textarea>
	      </div>
	    </div>

      <div class="row">
        <div class="col-12 text-center">
          <input @click="sendMail()" type="submit" value="Send Message" class="btn btn-primary rounded-0 px-3 px-5">
        </div>
      </div>
		</div>

		<div v-else>
			<div class="row justify-content-center my-5">
        <div class="col-md-7 text-center my-5">
         	<h1 class="text-serif text-primary my-5">We will send you a message as soon as possible</h1>
        </div>
    	</div>
		</div>

	</div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueElementLoading from 'vue-element-loading'
import _ from 'lodash';
import { inputError, inputErrorMsg } from '../tools'
import errorlist from '../ErrorsList'

export default {

  name: 'Quoter',

  components: { DatePicker, VueElementLoading, errorlist, VuePhoneNumberInput },

  mounted(){
 		this.getYatchs();
  },

  data () {
    return {
    	contacto:{name:'', email:'', mobile:'', message:''},

    	yatchs:null,
    	prices:null,
    	maxPeople:0,

    	people:'',
    	loading:false,
    	sending:false,
    	sent:false,
    	people:'',
    	hours:'',
    	disabledDate:true,
    	yatch:null,
    	disableYatch:false,
    	disabledPeople:true,
    	disabledHours:true,
    	date:null,
    	total:0,
    	errors:[],
    	nationalNumber:''
    }
    
  },

  methods:{
  	phone(value){
  		value.isValid ? this.nationalNumber = value.e164 : this.nationalNumber='';
  	},
  	getYatchs(){
  		window.axios.get('/yatchs/get').then(response => {
            this.yatchs = response.data;   
                       
        }).catch(error => {
            this.loading = false
            console.log(error.response.data.errors)
            this.handleErrorMessage(error)
            this.errors = error.response.data.errors;
        })
  	},

  	selectYatchAndGetPrices(id){
  		var index = _.findIndex(this.yatchs, function(o) { return o.id === id; });
  		this.prices = this.yatchs[index].prices;
  		this.maxPeople = this.yatchs[index].people;

  		if (this.yatch) {
  			this.disabledPeople = false; 
  			this.people='';
  			this.date=null;
  			this.disabledDate = true
  			this.hours=''
  			this.disabledHours=true
  			this.total=0;
  		}

  	},

  	selectPeople(){
  		if (this.people > 0) {this.disabledDate=false}
  	},

  	selectDate(){
  		if (this.date){this.disabledHours=false;}  		
  	},

  	calculatePrice(){
  		if (this.hours) {
  			this.loading = true
  			
  			setTimeout(() => {
  				this.total = this.formatPrice(this.hours.price);
  				this.loading = false;
				},1000);
  		}
  	},

  	disabledBeforeToday(date){
  		const today = new Date();
  		return date < today;
  	},

  	formatPrice(value) {
  		if (value.match("^[_0-9].*$")) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return '$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' MX'
        }
      return value;
    },

    sendMail(){
    	console.log(this.phone.nationalNumber)
    	this.sending=true
    	var form = new FormData();
      form.append('name', this.contacto.name);
      form.append('email', this.contacto.email);
      form.append('mobile', this.nationalNumber);
      form.append('message', this.contacto.message);
      form.append('yatch', this.yatch ? this.yatch.name : '');
      form.append('people', this.people);
      form.append('date', this.date ?? '') ;
      form.append('hours', this.hours ? this.hours.hours : '');
      form.append('total', this.total);

      window.axios.post('/contactQuoter', form).then(response => {
            if (response) {
            	this.sending=false
            	this.sent = true
            }
            
            
        }).catch(error => {
        	this.sending=false
        	this.errors = error.response.data.errors;
          console.log(error)
        })

    	// setTimeout(() => {
  		// 		this.sending = false;
  		// 		this.sent = true;
			// },2000);
			// 
    },

    limpiar(){

    },

  	isCelular() {
	    if (screen.width <= 767) {
	      return true
	    } else {
	      return false
	    }
	  },
	  errorLine(field){
	  	if (this.errors) { if (this.errors[field]) {return 'inputError'}}
	  }
  }
}
</script>

<style scope>
	.fix{
    height: calc(2.875rem + 2px);
	}

	.mx-datepicker{
		width: 100% !important;
	}

	.header-fix{
		padding-top: 120px;
	}
</style>