import Vue from 'vue'
import config from './config'

// Vue Fontawesome 
require('./fontawesome.js')

// Axios
import axios from 'axios'
axios.defaults.baseURL = config.publicUrl
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios = axios

// Set Global Vars
Vue.prototype.$publicUrl = config.publicUrl
Vue.prototype.$appName = config.appName
Vue.prototype.$token = config.token

// Vue Config
Vue.config.productionTip = false

// disable vue devtools in production
if (process.env.MIX_APP_DEBUG=='true') {Vue.config.devtools = true}
else{Vue.config.devtools = false}
console.log(process.env.MIX_APP_DEBUG)

// Components
Vue.component('Gallery', require('./components/Gallery.vue').default);
Vue.component('Yatch', require('./components/Yatch.vue').default);
Vue.component('Quoter', require('./components/Quoter.vue').default);
Vue.component('Contact', require('./components/Contact.vue').default);
Vue.component('Sharer', require('./components/Sharer.vue').default);
Vue.component('Bottom_buttons', require('./components/BottomButtons.vue').default);
Vue.component('Banner', require('./components/Banner.vue').default);


// Share Window Dialog
import { shareDialog, shareOnPage } from './share_tools'

new Vue({
    el: '#app',
    methods: {
        shareDialog: shareDialog,
        shareOnPage: shareOnPage
    }
})
