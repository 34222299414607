import config from './config'
// import dayjs from './dayjs'

export function formatCurrency (value) {
    var val = parseFloat(value)
    return '$' + val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}

export function numberWithCommas (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}


export function textTruncate (str, length = 100, ending = '...') {
    if (str.length > length) {  
        return str.substring(0, length - ending.length) + ending; 
    } else { return str }  
}

export function setZoneScrollToBottom (id_element) {
    var container = document.querySelector(id_element)
    container.scrollTop = container.scrollHeight
}

export function setScrollToElement (id_element) {
    var element = document.querySelector(id_element)
    const y = element.getBoundingClientRect().top + window.scrollY
    window.scroll({ top: y, behavior: 'smooth' })
}

export function checkIfUserOnline (active) {
    if (active == 1) { return 'online' }
    else { return 'disconnected' }
}

export function isValidFileSize (file, maxsize) {
    if (file.size > maxsize) { return false }
    { return true }
}

export function isValidImageExtension (file) {
    if (!/\.(jpg|jpeg|png|gif)$/i.test(file.name)) {
        return false
    } else { return true }
}

export function isStorageFile (path, isBlob = false) {
    if (isBlob) { return path }
    if (!/^(f|ht)tps?:\/\//i.test(path)) {
        return config.publicUrl + 'storage/' + path
    } else { return path }
}

export function paginate (array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size)
}

export function randomString (length = 8) {
    return Math.random().toString(16).substr(2, length)
}

export function formatBytes (bytes, decimals) {
    if (bytes == 0) { return '0 Bytes' }
    var k = 1000,
        dm = decimals + 1 || 3,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function inputError (errors, field, classname) {
    if(errors) { if(errors[field]) { return classname; } } return ''
}

export function inputErrorMsg (errors, field) {
    if(errors) { if(errors[field]) { return errors[field][0]; } } return ''
}

export function convertDateToString(date = new Date()) {
    if (date == null) { return '' }
    return dayjs(date).format('YYYY-MM-DD')
    //return date.toLocaleString().substring(10, 0).replace(/\//g, '-')
}

export function convertStringToDate(str_date) {
    if (str_date == '') { return null }
    return new Date(str_date.replace(/-/g, '/'))
}

export function getDateMinusDay(day_minus) {
    var d = new Date();
    return d.setDate(d.getDate() - day_minus)
}

export function slugify(value) {
    value = value.replace(/^\s+|\s+$/g, '')
    value = value.toLowerCase()
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    var to   = "aaaaaeeeeeiiiiooooouuuunc------"
    for (var i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
    value = value.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-')
    return value
}

export function debounce(func, timeout = 300) {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => { func.apply(this, args) }, timeout)
    }
}

export function shareSocialMedia(media, url, title = ''){
    switch (media) {
        case 'facebook':
            return 'https://www.facebook.com/sharer/sharer.php?u=' + url
        case 'twitter':
            return 'https://twitter.com/intent/tweet?text=' + url
        case 'linkedin':
            return 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + title + '&summary=&source='
        case 'whatsapp':
            return 'whatsapp://send?text=' + url
        case 'email':
            return 'mailto:?subject=' + title + '&body='  + url
        default: 
            return url
    }
}
