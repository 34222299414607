import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faPen, faShieldAlt, faWindowClose, faUserCircle, faArrowCircleDown, faAt,
    faUsers, faEnvelope
} from '@fortawesome/free-solid-svg-icons'

import { faFacebook, faTwitter, faWhatsapp, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faFacebook, faTwitter, faWhatsapp, faLinkedin, faPen, faShieldAlt, faWindowClose, faUserCircle,
    faArrowCircleDown, faAt, faUsers, faEnvelope, faInstagram
)

Vue.component('font-awesome-icon', FontAwesomeIcon)