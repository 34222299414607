<template>
	<div class="d-flex justify-content-center" v-if="isCelular()">
		<div class="whatsapp ">
			<p class="textSize text-center">
				For Special Prices: <br>
				<!-- +52 61215 35535 <br> -->
				chartersgobaja@gmail.com
			</p>
		</div>
	</div>
</template>
<script>
export default {

  name: 'Banner',

  data () {
    return {
    	isCelular() {
		    if (screen.width <= 767) {
		      return true
		    } else {
		      return false
		    }
		},
    }
  }
}
</script>

<style lang="css" scoped>
.whatsapp {
	width: 80%;
	position:fixed;
/*	left:auto;*/
/*	right: auto;*/
	top:75px;
/*	right:40px;*/
	background-color:white;
	color:black;
	text-align:center;
	font-size:35px !important;
	z-index:100;
	box-shadow: 10px 10px 5px gray;
}

.textSize{
	font-size: 20px;
}
</style>