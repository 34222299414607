<template>
    <div class="alert alert-danger" role="alert" v-if="errors.length != 0">
        <ul>
            <li v-for="(error, i) in errors" :key="i">
                {{error[0]}}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        errors: {
            type: [Array, Object],
            required: true
        }
    },
}
</script>
<style>
.inputError {
    outline: 'none';
    border-color: red;
    -webkit-box-shadow: 0px 0px 17px 0px rgba(255, 0, 0, 1);
    -moz-box-shadow:    0px 0px 17px 0px rgba(255, 0, 0, 1);
    box-shadow:         0px 0px 17px 0px rgba(255, 0, 0, 1);
}
</style>