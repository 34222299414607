<template>
	<div class="site-section" id="contact">
		<vue-element-loading :active="sending" spinner="spinner" color="#1089ff"/>
	    <div class="container">
	      <div class="row justify-content-center mb-5">
	        <div class="col-md-7 text-center">
	          <span class="text-serif text-primary">Send us A Message</span>
	          <h3 class="heading-92913 text-black text-center">Contact US</h3>
	        </div>
	      </div>
	    
	        <div class="row">
	            <div class="col-md-6 form-group">
	                <label for="fname">First Name</label>
	                <input type="text" id="fname" v-model="contacto.name" :class="errors.name?'inputError':'' " class="form-control form-control-lg">
	            </div>
	            <div class="col-md-6 form-group">
	                <label for="lname">Last Name</label>
	                <input type="text" id="lname"  v-model="contacto.lastname" :class="errors.lastname?'inputError':'' " class="form-control form-control-lg">
	            </div>
	        </div>
	        <div class="row">
	            <div class="col-md-6 form-group">
	                <label for="eaddress">Email Address</label>
	                <input type="text" id="eaddress"  v-model="contacto.email" :class="errors.email?'inputError':'' " class="form-control form-control-lg">
	            </div>
	            <div class="col-md-6 form-group">
	                <label for="tel">Tel. Number</label>
	                <VuePhoneNumberInput size="lg" :no-flags="false" default-country-code="MX" :only-countries="['MX','US','FR','IT','DE','GB','ES','RU']" type="text" id="fname" v-model="contacto.mobile"  @update="phone" :class="errors.mobile?'inputError':'' "/>
	                <!-- <input type="text" id="tel"  v-model="contacto.mobile" :class="errors.mobile?'inputError':'' " class="form-control form-control-lg"> -->
	            </div>
	        </div>
	        <div class="row">
	            <div class="col-md-12 form-group">
	                <label for="message">Message</label>
	                <textarea name=""  v-model="contacto.message" :class="errors.message?'inputError':'' " id="message" cols="30" rows="10" class="form-control"></textarea>
	            </div>
	        </div>

	        <div class="row">
	            <div class="col-12">
	                <input type="submit" value="Send Message" @click="sendMail" v-if="!sent" class="btn btn-primary rounded-0 px-3 px-5">
	                <h1 v-else class="text-serif text-primary text-center">We will send you a message as soon as possible</h1>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import errorlist from '../ErrorsList';
import VueElementLoading from 'vue-element-loading';

export default {

  name: 'Contact',

  components: {VueElementLoading, errorlist, VuePhoneNumberInput },

  data () {
    return {
    	contacto:{name:'', lastname:'', email:'', mobile:'', message:''},
    	sent:false,
    	sending:false,
    	errors:[],
    	nationalNumber:''
    }
  },
  methods:{
  	phone(value){
  		value.isValid ? this.nationalNumber = value.e164 : this.nationalNumber='';
  	},

  	sendMail(){
  		this.sending=true
    	var form = new FormData();
	    form.append('name', this.contacto.name);
	    form.append('lastname', this.contacto.lastname);
	    form.append('email', this.contacto.email);
	    form.append('mobile', this.nationalNumber);
	    form.append('message', this.contacto.message);

	    window.axios.post('/contact', form).then(response => {
	          	this.sending=false
	           	this.sent = true
	           	this.clear();
	        }).catch(error => {
	        	this.sending=false
	        	this.errors = error.response.data.errors;
	          console.log(error)
	        })
	  	},

	  	clear(){
	  		this.contacto={name:'', lastname:'', email:'', mobile:'', message:''}
	  	}
  }
}
</script>

<style lang="css" scoped>
</style>