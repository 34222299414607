<template>
  <div class="container py-6" id="gallery">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center"  data-aos="fade-down">
        <span class="text-serif text-primary">see our gallery</span>
        <h3 class="heading-92913 text-black text-center">Galería</h3>
      </div>
    </div>

    <!-- directive -->
    <div class="images" v-viewer>
      <img v-for="src in photos" :src="publicUrl + isStorageFile(src.image)" style="width: 20%;">
    </div>
  </div>
</template>

<script>
  import 'viewerjs/dist/viewer.css'
  import VueViewer from 'v-viewer'
  import Vue from 'vue'

  Vue.use(VueViewer)

  export default {
    mounted(){
      this.getPhotos();
    },
    data() {
      return {
        publicUrl: this.$publicUrl,
        photos:[]
      };
    },
    methods: {
      show() {
        this.$viewerApi({
          photos: this.photos,
        })
      },
      getPhotos:function(){
        window.axios.get('/gallery/photos/vue', {
        }).then(response => {
            // success(response.data)
            // console.log(response.data);
            this.photos = response.data;
        }).catch(error => {
            console.log(error)
        })
      },

      isStorageFile:function (path, isBlob = false) {
        if (isBlob) { return path }
        if (!/^(f|ht)tps?:\/\//i.test(path)) {
            return 'storage/' + path
        } else { return path }
      }
    },
  }
</script>
<style scoped>
  img {padding: 1px;}
</style>