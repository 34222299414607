<template>
    <div class="social-share">
        <!-- <center><h4>Comparte esta publicación</h4></center> -->
       <a @click="openLink(shareSocialMedia('facebook', metas.url))" class="mr-4 is-inline-block share-fb">
            <i class="icon-facebook-square mx-3" style="font-size: 70px; color:#1089ff"></i>
        </a>
        <a @click="openLink(shareSocialMedia('twitter', metas.url))" class=" mr-4 is-inline-block share-twitter">
            <i class="icon-twitter-square mx-3" style="font-size: 70px; color:#1089ff"></i>
        </a>
          
        <a @click="openLink(shareSocialMedia('whatsapp', metas.url))" class="mr-4 is-inline-block fa fa-whatsapp">
            <i class="icon-whatsapp mx-3" style="font-size: 70px; color:#1089ff"></i>
        </a>

        <a @click="openLink(shareSocialMedia('email', metas.url, metas.title))" class="mr-4 is-inline-block fa fa-mail">
            <i class="icon-mail_outline" style="font-size: 70px; color:#1089ff"></i>                
        </a>                
    </div>
</template>

<script>
import { shareSocialMedia } from '../tools'


export default {

    mounted() {
        this.getMetaTags()
    },

    data() {
        return {
            loading: false,
            metas: {
                title: '',
                url: '',
                image:''
            }
        }
    },
    methods: {
        openLink(string) {
            window.open(string, "social_media_share", "height=320, width=640, toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, directories=no, status=no")
        },
        getMetaTags() {
            this.metas.title = document.head.querySelector('meta[property="og:title"]').getAttribute('content')
            this.metas.url = document.head.querySelector('meta[property="og:url"]').getAttribute('content')
            this.metas.image = document.head.querySelector('meta[property="og:image"]').getAttribute('content')
        },
        shareSocialMedia:shareSocialMedia
    }
}
</script>