<template>
  <div v-if="yatch">
    <div class="site-section mt-5">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-7 text-center">
            <h3 class="heading-92913 text-black text-center" v-text="yatch.name"></h3>
          </div>
        </div>  
        <div class="row text-center">
          <div class="col my-auto"  data-aos="fade-right">
            <img :src="publicUrl + isStorageFile(yatch.image)" alt="imageYatch" class="img-fluid center-block" >
          </div>
        </div>
        <div class="row mt-5 text-center" >
          <div class="col">
            <div v-html="yatch.description" class="text-center"></div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="container py-6">
      <div class="row justify-content-center mb-5">
        <div class="col-md-7 text-center">
          <!-- <span class="text-serif text-primary">see our gallery</span> -->
          <h3 class="heading-92913 text-black text-center">Yatch Gallery</h3>
        </div>
      </div>

      <h2 class="title is-size-2 has-text-centered">Galeria</h2>
      <!-- directive -->
      <div class="images" v-viewer>
        <img v-if="yatch.gallery" v-for="src in yatch.gallery" :src="publicUrl + isStorageFile(src.image)" style="width: 20%;" data-aos="zoom-in" data-aos-duration="2000">
      </div>

    </div>
  </div>
</template>

<script>
// import { isStorageFile } from '../tools'
  // import 'viewerjs/dist/viewer.css'
  // import VueViewer from 'v-viewer'
  // import Vue from 'vue'
  // import AOS from 'aos'
  // import 'aos/dist/aos.css'

  // Vue.use(VueViewer)

  export default {
    mounted(){

      const url = window.location.href;
      const lastParam = url.split("/").slice(-1)[0];
      this.getData(lastParam);
    },
    created () {
      // AOS.init()
    },

    data() {
      return {
        yatch:null,
        public_url:"images/",
        publicUrl: this.$publicUrl,

        images: [
          "img/pics/Manta/1.jpg",
          "img/pics/Manta/2.jpg",
          "img/pics/Manta/3.jpg",
          "img/pics/Manta/4.jpg",
          "img/pics/Manta/5.jpg",
          "img/pics/Manta/6.jpg",
          "img/pics/Manta/7.jpg",
          "img/pics/Manta/8.jpg",
          "img/pics/Manta/9.jpg",
          "img/pics/Manta/10.jpg",
          "img/pics/Manta/11.jpg",
          "img/pics/Manta/12.jpg",
          "img/pics/Manta/13.jpg",
          "img/pics/Manta/14.jpg",
          "img/pics/Manta/15.jpg",
          "img/pics/Manta/16.jpg",
          "img/pics/Manta/17.jpg", 
          "img/pics/Manta/18.jpg", 
          "img/pics/Manta/19.jpg",          
        ],
        photos:[]
      };
    },
    methods: {
      getData(id){
        // console.log(id);
        axios.get(`yatchs/getYatch/${id}`).then(response => {
           this.yatch = response.data;         
        }).catch(error => {
            this.loading = false
            console.log(error)
            // this.handleErrorMessage(error)
            // this.getErrors = error.response.data.errors;
        })
      },
      show() {
        this.$viewerApi({
          photos: this.photos,
        })
      },
      getPhotos:function(){
        window.axios.get('/gallery/photos', {
        }).then(response => {
            // success(response.data)
            // console.log(response.data);
            this.photos = response.data;
        }).catch(error => {
            console.log(error)
            // failure(error.response.data.errors['image'][0])
        })
      },

      isStorageFile:function (path, isBlob = false) {
        // var publicUrl: document.head.querySelector('meta[name="public_url"]').getAttribute('content')
        if (isBlob) { return path }
        if (!/^(f|ht)tps?:\/\//i.test(path)) {
          // alert('storage/' + path);
            return 'storage/' + path
        } else { return path }
      },

      isPublicImg (path, isBlob = false) {
          if (isBlob) { return path }
          if (!/^(f|ht)tps?:\/\//i.test(path)) {
              return this.$publicUrl+ path
          } else { return path }
      }
    },
    // isStorageFile: isStorageFile
  }
</script>
<style scoped>
  img {padding: 1px;}
</style>