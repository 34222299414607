const dialog_options = 'height=320, width=640, toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, directories=no, status=no'

export function shareFacebook(url) {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook_share', dialog_options)
}

export function shareTwitter(title, url) {
    window.open('https://twitter.com/share?text=' + title + '&url=' + url, 'twitter_share', dialog_options)
}

export function shareLinkedin(url) {
    window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + url, 'linkedin_share', dialog_options)
}

export function shareGooglePlus(url) {
    window.open('https://plus.google.com/share?url=' + url, 'google_share', dialog_options)
}

export function shareWhatsApp(url) {
    location.href = 'https://wa.me/?text=' + url
}

export function shareEmail(url, title) {
    location.href = 'mailto:?subject=' + title + '&body=' + url
}

export function shareDialog(url, window_name) {
    window.open(url, window_name, dialog_options)
}

export function shareOnPage(url) {
    location.href = url
}